export const translationsEn = {
  categories: "Categories",
  "about-us": "About Us",
  "credit-terms": "Credit terms",
  contacts: "Contacts",
  placeholder: "Enter your product name",
  sale: "Sale",
  "new-collection": "New collection",
  bestsellers: "Bestsellers",
  viewAll: "View all",
  brands: "Brands",
  openDaysTop: "Mon-Sat 10:00 - 19:00",
  openDaysBottom: "Sunday 11:00 - 19:00",
  addressTop: "39 Nairyan St",
  addressBottom: "Sevan, Armenia",
  toTop: "Top",
  buy: "Buy",
  addToCart: "Add to cart",
  nothingFound: "Nothing Found",
  sortBy: "Sort by",
  clearAll: "Clear all",
  brand: "Brand",
  price: "Price",
  min: "Min",
  max: "Max",
  outOfStock: "Out of stock",
  contactUs: "Contact us",
  "make-order": "Make order",
  name: "Name",
  surname: "Surname",
  email: "Email",
  phone: "Phone",
  address: "Delivery address",
  region: "Region",
  select: "Select",
  selectRegion: "Select region",
  message: "Message",
  addComment: "Add comment (optional)",
  paymentMethod: "Payment method",
  deliveryMethod: "Delivery type",
  selectPaymentMethod: "Select payment method",
  selectDeliveryMethod: "Select delivery type",
  cash: "Cash",
  online: "Online",
  terminal: "Pos-terminal",
  delivery: "Delivery",
  pickup: "Pickup",
  orderDetails: "Order details",
  discount: "Discount",
  total: "Total for payment",
  productXpcs: "Product, {{count}} pcs",
  free: "Free",
  confirmOrder: "Confirm order",
  back: "Back to shopping",
  fieldsRequired: "All fields are required",
  send: "Send",
  required: "This field is required.",
  requiredPhone: "Please enter valid phone number.",
  requiredEmail: "Invalid email address.",
  cart: "Your cart",
  emptyCartTitle: "Your cart is empty",
  emptyCartSubtitle: "Looks like You have not make Your choice yet...",
  deleteAll: "Delete all",
  compare: "Compare items",
  all: "All",
  differnces: "Only differences",
  emptyCompare: "Empty comparing items",
  months: "Months",
  withCash: "Cash",
  withCard: "Card",
  moreInformation: "More information",
  relateds: "Related Products",
  quantity: "Quantity",
  iWantToOrder: "I want to order",
  home: "Home",
  addedToCart: "Added to cart",
  openCart: "Open cart",
  pcs: "pcs",
  confirmed: "Order confirmed!",
  thanks: "Thanks for shopping with us!",
  continue: "Continue",
  failed: "Order failed!",
  rejectedOrder: "Please check Your payment details and try again.",
  tryAgain: "Try again",
  contactThanks: "Thank you for contacting us!",
  weHaveReceived:
    "We have received your message! We will reached you out imminently.",
  aboutUsTop:
    "Welcome to “Tesvan”, where expertise meets innovation. We are an outsourcing company specializing in software testing and QA services. Founded in 2020, we excel in Quality Assurance while offering the latest electronic gadgets. Our team of industry experts guarantees the highest quality in both our services and product offerings.",
  aboutUsBottom:
    "We are dedicated to customer satisfaction and constantly seek the latest technology to serve you better. Explore our products and experience precision and innovation firsthand.",
  aboutUsEnding: "We look forward to seeing you.",
  conditions: "CREDIT CONDITIONS",
  condition1:
    "In Tesvan Electronics shop the credit purchase is available for 20-63 age group",
  condition2: "Be a registered employee or have a clear credit history",
  condition3: "Have a passport and social card",
  provideds: "PROVIDED CREDIT TERMS",
  provided1: "0% prepayment",
  provided2: "0-12% interest rate (according to the preferred bank)",
  provided3: "3-60 months maturity date",
  provided4:
    "Account maintenance fee starting from 1% of the total cost per month, depending on the choice of the bank",
  partners: "PARTNER BANKS",
  partner1: "ACBA-CREDIT AGRICOLE BANK CJSC",
  partner2: "ARMECONOMBANK OJSC",
  partner3: "INECOBANK CJSC",
  partner4: "VTB ARMENIA BANK CJSC",
  partner5: "AMERIA BANK CJSC",
  partner6: "UNIBANK CJSC",
  partner7: "ARARAT BANK CJSC",
  partner8: "ARMBUSINESS BANK CJSC",
  call: "For more information you can call +374 55 49 19 89",
  allRights: "2024 The Content Folk All rights reserved",
  code: "Code",
  agree: "I have read and agree with the",
  policy: "Privacy Policy",
  "privacy-policy": "Privacy Policy",
  amd: "AMD",
  opps: "OPPS! Page not found",
  backToHomePage: "Back to home page",
  highToLow: "Price: high to low",
  lowToHigh: "Price: low to high",
  oldest: "Oldest",
  newest: "Newest",
  lessQuantity: "The quantity of this device is less than you want",
  somethingWentWrong: "Something went wrong",
  processor: "Processor",
  payment: "Payment",
  "card-number": "Card number",
  "card-holder": "Card holder",
  "min-length": "Please enter at least {{number}} digits",

  // Policy
  privacy: {
    title:
      "Tesvan Electronics is committed to maintaining the privacy of its customers and assures that no personal data will be provided to a third party. Tesvan Electronics strictly observes the Personal Data Protection Act.",
    viewing: "Viewing and Editing Personal Data",
    sendEmail:
      "According to the Personal Data Protection Law, you have the right to view and edit your personal information collected by us. To do so, please send an email to",
    regards: "Best Regards`",
    general: {
      title: "General Terms",
      title1: "1. Information We Collect",
      subtitle1: "We collect various types of information, including:",
      desc1:
        "a. Personal Information: This may include your name, address, email address, phone number, and payment information.",
      desc2:
        "b. Order Information: Details about the products you order, delivery preferences, and payment details.",
      desc3:
        "c. Delivery Information: If you opt for our self-delivery option, we may collect location data for accurate and timely delivery.",
      desc4:
        "d.Communication Information: Records of your correspondence with our customer support including emails and chat transcripts.",
      title2: "2. How We Use Your Information",
      subtitle2:
        "We use the information we collect for the following purposes:",
      desc5:
        "a. Order Processing: To process and fulfill your orders, including arranging delivery and contacting you for address clarification if necessary.",
      desc6:
        "b. Customer Service: To respond to your inquiries, provide support, and address any issues you may encounter.",
      desc7:
        "c. Payment Processing: To facilitate payment for your orders, we may use secure third-party payment processors.",
      desc8:
        "d. Marketing and Promotions: With your consent, we may send you promotional offers, newsletters, and information about new products or services.",
      title3: "3. Sharing of Information",
      subtitle3:
        "We do not sell or rent your personal information to third parties. However, we may share your information with:",
      desc9:
        "a. Service Providers: Such as delivery partners, payment processors, and customer service providers, who help us carry out various functions.",
      desc10:
        "b. Legal Compliance: If required by law or in response to a valid legal process, we may disclose your information.",
      title4: "4. Security",
      desc11:
        "We take appropriate measures to protect your information from unauthorized access, disclosure, alteration, and destruction.",
      title5: "5. Changes to this Policy",
      desc12:
        "We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. The updated policy will be posted on our website.",
      title6: "6. Contact Us",
      desc13:
        "If you have any questions or concerns about this Privacy Policy, please contact us.",
    },
  },
};
export const translationsRu = {
  categories: "Категории",
  "about-us": "O нас",
  "credit-terms": "Условия кредита",
  contacts: "Контакты",
  placeholder: "Введите название вашего продукта",
  sale: "Распродажа",
  "new-collection": "Новая коллекция",
  bestsellers: "Бестселлеры",
  viewAll: "Cмотреть все",
  brands: "Бренды",
  openDaysTop: "Пн-Сб 10:00 - 19:00",
  openDaysBottom: "Воскресенье 11:00 - 19:00",
  addressTop: "Ул. Наирян 45",
  addressBottom: "Армения, Севан",
  toTop: "Вверх",
  buy: "Купить",
  addToCart: "Добавить",
  nothingFound: "Ничего не найдено",
  sortBy: "Сортировать по",
  clearAll: "Очистить все",
  brand: "Бренд",
  price: "Цена",
  min: "Мин",
  max: "Мах",
  outOfStock: "Нет в наличии",
  contactUs: "Связаться с нами",
  "make-order": "Сделать заказ",
  name: "Имя",
  surname: "Фамилия",
  phone: "Номер телефона",
  email: "Электронная почта",
  address: "Адрес доставки",
  region: "Регион",
  select: "Выбрать",
  selectRegion: "Выберите регион",
  message: "Сообщение",
  addComment: "Добавить комментарий (необязательный)",
  paymentMethod: "Способ оплаты",
  deliveryMethod: "Время доставки",
  selectPaymentMethod: "Выберите метод оплаты",
  selectDeliveryMethod: "Выберите время доставки",
  cash: "Оплата наличными",
  online: "Онлайн",
  terminal: "POS-терминал",
  delivery: "Доставка",
  pickup: "Пикап",
  orderDetails: "Детали заказа",
  total: "Общая сумма к оплате",
  discount: "Скидка",
  productXpcs: "Товар, {{count}} шт",
  pcs: "шт",
  free: "бесплатная",
  confirmOrder: "Подтвердить",
  back: "Вернуться к товарам",
  fieldsRequired: "Все поля обязательны для заполнения",
  send: "Отправить",
  required: "Это поле обязательно к заполнению.",
  requiredPhone: "Введите правильный номер телефона.",
  requiredEmail: "Введите правильную электронную почту.",
  cart: "Ваша корзина",
  emptyCartTitle: "Ваша корзина пуста",
  emptyCartSubtitle: "Похоже, Вы еще не сделали свой выбор...",
  deleteAll: "Удалить все",
  compare: "Сравнить товары",
  all: "Все",
  differnces: "Только отличия",
  emptyCompare: "Нет предметов для сравнение",
  months: "Мес.",
  withCash: "Наличными",
  withCard: "Картой",
  moreInformation: "Подробнее о товаре",
  relateds: "Похожие продукты",
  quantity: "Количество",
  iWantToOrder: "Я хочу заказать",
  home: "Главное",
  addedToCart: "Добавлен",
  openCart: "Открыть корзину",
  confirmed: "Заказ подтвержден!",
  thanks: "Спасибо, что делаете покупки у нас!",
  continue: "Продолжить",
  failed: "Не удалось выполнить заказ.",
  rejectedOrder:
    "Пожалуйста, проверьте свои платежные данные и повторите попытку.",
  tryAgain: "Повторите попытку",
  contactThanks: "Спасибо, что связались с нами!",
  weHaveReceived: "Мы получили Ваше сообщение! Мы немедленно свяжемся с вами.",
  aboutUsTop:
    "Добро пожаловать в «Тесван», где опыт сочетается с инновациями. Мы аутсорсинговая компания, специализирующаяся на тестировании программного обеспечения и услугах контроля качества. Основанная в 2020 году, мы преуспеваем в обеспечении качества и предлагаем новейшие электронные гаджеты. Наша команда отраслевых экспертов гарантирует высочайшее качество как наших услуг, так и предлагаемых продуктов.",
  aboutUsBottom:
    "Мы стремимся удовлетворить потребности клиентов и постоянно ищем новейшие технологии, чтобы лучше обслуживать вас. Изучите нашу продукцию и испытайте точность и инновации на собственном опыте.",
  aboutUsEnding: "Мы будем рады видеть вас.",
  conditions: "Для оформления кредита необходимо",
  condition1:
    "В магазине Tesvan Electronics, для посетителей возрастной группы 20-63 лет, есть возможность совершить покупку в кредит.",
  condition2:
    "Быть оформленным на месте работы сотрудником или иметь безупречную кредитную историю",
  condition3: "Иметь паспорт и соцкарту",
  provideds: "Условия предоставления кредита",
  provided1: "0% предоплаты",
  provided2: "0-12% годовых (согласно привилегированному банку)",
  provided3: "Срок погашения от 3 до 60 месяцев",
  provided4:
    "Комиссия за обслуживание счета от 1% от общей стоимости в месяц, в зависимости от выбора банка.",
  partners: "Партнерские банки",
  partner1: "ACBA-CREDIT AGRICOLE BANK CJSC",
  partner2: "ARMECONOMBANK OJSC",
  partner3: "INECOBANK CJSC",
  partner4: "VTB ARMENIA BANK CJSC",
  partner5: "AMERIA BANK CJSC",
  partner6: "UNIBANK CJSC",
  partner7: "ARARAT BANK CJSC",
  partner8: "ARMBUSINESS BANK CJSC",
  call: "Для получения подробной информации вы можете позвонить по +374 55 49 19 89 номеру телефона.",
  allRights: "2024 Все права защищены",
  code: "Код",
  agree: "Я прочитал и согласен с",
  policy: "Политикой конфиденциальности.",
  "privacy-policy": "Политика конфиденциальности",
  amd: "драм",
  opps: "Упс! Страница не найдена",
  backToHomePage: "Вернуться на главную",
  highToLow: "Цена: по убыванию",
  lowToHigh: "Цена: по возрастанию",
  oldest: "Старейшие",
  newest: "Новейшие",
  lessQuantity: "Количество этого устройства меньше, чем вы хотите.",
  somethingWentWrong: "Что-то пошло не так",
  processor: "Процессор",
  payment: "Оплата",
  "card-number": "Номер карты",
  "card-holder": "Владелец карты",
  "min-length": "Пожалуйста, введите не менее {{number}} цифр",

  // Policy
  privacy: {
    title:
      "Tesvan Electronics стремится сохранять конфиденциальность своих клиентов и гарантирует, что никакие персональные данные не будут переданы третьим лицам. Tesvan Electronics строго соблюдает Закон о защите персональных данных.",
    viewing: "Просмотр и редактирование личных данных",
    sendEmail:
      "В соответствии с Законом о защите персональных данных вы имеете право просматривать и редактировать вашу личную информацию, собранную нами. Для этого отправьте электронное письмо на адрес",
    regards: "С уважением`",
    general: {
      title: "Общие условия",
      title1: "1. Информация, которую мы собираем",
      subtitle1: "Мы собираем различные типы информации, в том числе:",
      desc1:
        "а. Личная информация: она может включать Ваше имя, адрес, адрес электронной почты, номер телефона и платежную информацию.",
      desc2:
        "б. Информация о заказе: Подробная информация о заказанных Вами продуктах, предпочтениях по доставке и деталях оплаты.",
      desc3:
        "в. Информация о доставке: Если Вы выберете вариант самовывоза, мы можем собирать данные о местоположении для точной и своевременной доставки.",
      desc4:
        "д. Информация для связи: записи Вашей переписки с нашей службой поддержки, включая электронные письма и стенограммы чатов.",
      title2: "2. Как мы используем Вашу информацию",
      subtitle2:
        "Мы используем информацию, которую собираем, для следующих целей:",
      desc5:
        "а. Обработка заказов: для обработки и выполнения Ваших заказов, включая организацию доставки и связь с Вами для уточнения адреса при необходимости.",
      desc6:
        "б. Служба поддержки клиентов: чтобы отвечать на Ваши запросы, оказывать поддержку и решать любые проблемы, с которыми Вы можете столкнуться.",
      desc7:
        "в. Обработка платежей: Чтобы облегчить оплату Ваших заказов, мы можем использовать безопасные сторонние платежные системы.",
      desc8:
        "д. Маркетинг и рекламные акции: с Вашего согласия мы можем отправлять Вам рекламные предложения, информационные бюллетени и информацию о новых продуктах или услугах.",
      title3: "3. Обмен информацией",
      subtitle3:
        "Мы не продаем и не сдаем в аренду вашу личную информацию третьим лицам. Однако мы можем передать Вашу информацию:",
      desc9:
        "а. Поставщики услуг: такие как партнеры по доставке, платежные системы и поставщики услуг поддержки клиентов, которые помогают нам выполнять различные функции.",
      desc10:
        "б. Соблюдение юридических требований: если этого требует закон или в ответ на действительный судебный процесс, мы можем раскрыть вашу информацию.",
      title4: "4. Безопасность",
      desc11:
        "Мы принимаем соответствующие меры для защиты Вашей информации от несанкционированного доступа, раскрытия, изменения и уничтожения.",
      title5: "5. Изменения в настоящей Политике",
      desc12:
        "Мы можем время от времени обновлять настоящую Политику конфиденциальности, чтобы отражать изменения в нашей практике или по другим операционным, юридическим или нормативным причинам. Обновленная политика будет размещена на нашем сайте.",
      title6: "6. Свяжитесь с нами",
      desc13:
        "Если у Вас есть какие-либо вопросы или сомнения по поводу настоящей Политики конфиденциальности, свяжитесь с нами.",
    },
  },
};
export const translationsAm = {
  categories: "Տեսականի",
  "about-us": "Մեր մասին",
  "credit-terms": "Ապառիկ",
  contacts: "Կապ",
  placeholder: "Մուտքագրեք Ձեր ապրանքի անվանումը",
  sale: "Զեղչեր",
  "new-collection": "Նոր տեսականի",
  bestsellers: "Լավագույն վաճառք",
  viewAll: "Տեսնել բոլորը",
  brands: "Բրենդներ",
  openDaysTop: "Երկ - Շաբ 10:00 - 19:00",
  openDaysBottom: "Կիրակի 11:00 - 19:00",
  addressTop: "Հայաստան, ք, Սևան,",
  addressBottom: "փ․ Նաիրյան 45",
  toTop: "Վերև",
  buy: "Գնել",
  addToCart: "Ավելացնել",
  nothingFound: "Ոչինչ չի գտնվել",
  sortBy: "Դասակարգել ըստ",
  clearAll: "Ջնջել բոլորը",
  brand: "Բրենդ",
  price: "Գին",
  min: "Նվազ․",
  max: "Առ․",
  outOfStock: "Առկա չէ",
  contactUs: "Կապ մեզ հետ",
  "make-order": "Պատվիրել",
  name: "Անունը",
  surname: "Ազգանունը",
  email: "Էլ․ հասցե",
  phone: "Հեռախոսահամար",
  address: "Հասցե",
  region: "Տարածաշրջան",
  select: "Ընտրեք",
  selectRegion: "Ընտրեք տարածաշրջանը",
  message: "Հաղորդագրություն",
  addComment: "Մեկնաբանություն (ոչ պարտադիր)",
  paymentMethod: "Վճարման եղանակներ",
  deliveryMethod: "Առաքման տեսակ",
  selectPaymentMethod: "Ընտրել վճարման եղանակը",
  selectDeliveryMethod: "Ընտրել առաքման տեսակը",
  cash: "Վճարել կանխիկ",
  online: "Առցանց",
  terminal: "POS-տերմինալ",
  delivery: "Առաքում",
  pickup: "Ինքնառաքում",
  orderDetails: "Պատվերի դետալներ",
  discount: "Զեղչ",
  total: "Ընդհանուր արժեքը",
  productXpcs: "Ապրանք - {{count}} հատ",
  pcs: "հատ",
  free: "Անվճար",
  confirmOrder: "Հաստատել",
  back: "Վերադարձ",
  fieldsRequired: "Բոլոր դաշտերը պարտադիր են",
  send: "Ուղարկել",
  required: "Այս դաշտը պարտադիր է",
  requiredPhone: "Անվավեր հեռախոսահամար",
  requiredEmail: "Անվավեր էլ․ հասցե",
  cart: "Զամբյուղ",
  emptyCartTitle: "Ձեր զամբյուղը դատարկ է",
  emptyCartSubtitle: "Կարծես Դուք դեռ չեք կատարել Ձեր ընտրությունը...",
  deleteAll: "Ջնջել բոլորը",
  compare: "Համեմատել",
  all: "Բոլորը",
  differnces: "Միայն տարբերությունը",
  emptyCompare: "Համեմատելու ապրանքներ չկան",
  months: "Ամիս",
  withCash: "Կանխիկ",
  withCard: "Ապառիկ",
  moreInformation: "Մանրամասներ",
  relateds: "Նմանատիպ ապրանքներ",
  quantity: "Քանակ",
  iWantToOrder: "Ես ուզում եմ պատվիրել",
  home: "Գլխավոր",
  addedToCart: "Ավելացված",
  openCart: "Բացել զամբյուղը",
  confirmed: "Պատվերը կատարված է",
  thanks: "Շնորհակալություն գնումների համար!",
  continue: "Շարունակել",
  failed: "Չհաջողվեզ իրականցնել պատվերը",
  rejectedOrder: "Խնդրում ենք ստուգել Ձեր վճարման տվյալները և փորձել կրկին։",
  tryAgain: "Փորձել կրկին",
  contactThanks: "Շնորհակալություն մեզ հետ կապ հաստատելու համար",
  weHaveReceived:
    "Մենք ստացել ենք Ձեր նամակը։ Մենք կապ կհաստատենք Ձեզ հետ մոտ ժամանակահատվածում։",
  aboutUsTop:
    "Բարի գալուստ «Տեսվան Էլեկտրոնիկս», որտեղ փորձը հանդիպում է նորարարությանը: Մենք աութսորսինգ ընկերություն ենք, որը մասնագիտացած է ծրագրային ապահովման փորձարկման և որակի ապահովման ծառայությունների ոլորտում: Հիմնադրվելով 2020 թվականին՝ մենք գերազանցում ենք Որակի ապահովման ոլորտում՝ միաժամանակ առաջարկելով նորագույն էլեկտրոնային գաջեթները: ",
  aboutUsBottom:
    "Մեր թիմը երաշխավորում է ամենաբարձր որակը ինչպես մեր ծառայությունների, այնպես էլ արտադրանքի առաջարկներում:",
  aboutUsEnding: "Մենք անհամբեր սպասում ենք ձեզ:",
  conditions: "Ապառիկի պայմաններ",
  condition1:
    "Tesvan Electronics խանութ-սրահում ապառիկ տարբերակով գնումներ կատարելու հնարավորություն ունեն 20-63 տարիքային խմբին պատկանող անձիք։",
  condition2: "Լինել գրանցված աշխատող կամ ունենալ անթերի վարկային պատմություն։",
  condition3: "Ունենալ անձնագիր և սոցիալական քարտ։",
  provideds: "Տրամադրվող ապառիկի պայմանները",
  provided1: "0% կանխավճար",
  provided2: "0-12% տոկոսադրույք (ըստ նախընտրած բանկի)",
  provided3: "3-60 ամիս մարման ժամկետ",
  provided4:
    "Հաշվի սպասարկման վճարը` ամսական սկսած ընդհանուր արժեքի 1%-ից, կախված բանկի ընտրությունից",
  partners: "Գործընկեր բանկեր",
  partner1: "ACBA-CREDIT AGRICOLE BANK CJSC",
  partner2: "ARMECONOMBANK OJSC",
  partner3: "INECOBANK CJSC",
  partner4: "VTB ARMENIA BANK CJSC",
  partner5: "AMERIA BANK CJSC",
  partner6: "UNIBANK CJSC",
  partner7: "ARARAT BANK CJSC",
  partner8: "ARMBUSINESS BANK CJSC",
  call: "Մանրամասն տեղեկությունների համար կարող եք զանգահարել +374 55 49 19 89 հեռախոսահամարով։",
  allRights: "2024 Բոլոր իրավունքները պաշտպանված են",
  code: "Կոդ",
  agree: "Ես համաձայն եմ",
  policy: "Գաղտնիության քաղաքականությանը:",
  "privacy-policy": "Գաղտնիության քաղաքականություն",
  amd: "դրամ",
  opps: "Էջը չի գտնվել",
  backToHomePage: "Վերադառնալ գլխավոր էջ",
  highToLow: "Գին: թանկից դեպի էժան",
  lowToHigh: "Գին: էժանից դեպի թանկ",
  oldest: "Հնից նոր",
  newest: "Նորից հին",
  lessQuantity: "Այս ապրանքի քանակն ավելի քիչ է, քան ցանկանում եք։",
  somethingWentWrong: "Ինչ որ բան այնպես չգնաց",
  processor: "Պրոցեսոր",
  payment: "Վճարում",
  "card-number": "Քարտի համար",
  "card-holder": "Քարտի սեփականատեր",
  "min-length": "Խնդրում ենք մուտքագրել առնվազը {{number}} թիվ",

  // Policy
  privacy: {
    title:
      "Tesvan Electronics-ը պարտավորվում է պահպանել իր հաճախորդների գաղտնիությունը և վստահեցնում է, որ որևէ անձնական տվյալ չի տրամադրվի երրորդ կողմին: Tesvan Electronics-ը խստորեն պահպանում է Անձնական տվյալների պաշտպանության օրենքը:",
    viewing: "Անձնական տվյալների դիտում և խմբագրում",
    sendEmail:
      "Համաձայն Անձնական տվյալների պաշտպանության օրենքի՝ Դուք իրավունք ունեք դիտելու և խմբագրելու մեր կողմից հավաքագրված Ձեր անձնական տվյալները: Դրա համար խնդրում ենք նամակ ուղարկել",
    regards: "Հարգանքներով`",
    general: {
      title: "Ընդհանուր պայմաններ",
      title1: "1. Տեղեկություն, որը մենք հավաքում ենք",
      subtitle1: "Մենք հավաքում ենք տարբեր տեսակի տեղեկություններ, այդ թվում՝",
      desc1:
        "ա. Անձնական տվյալներ։ սա կարող է ներառել Ձեր անունը, ազգանունը, հասցեն, էլ․փոստի հասցեն, հեռախոսահամարը և վճարման տվյալները:",
      desc2:
        "բ. Պատվերի մասին տեղեկություններ։ Ձեր պատվիրած ապրանքների մանրամասները, առաքման դետալները և վճարման մանրամասները:",
      desc3:
        "գ. Առաքման մասին տեղեկություններ։ Եթե դուք ընտրում եք մեր ինքնաառաքման տարբերակը, մենք կարող ենք հավաքել տվյալներ ճշգրիտ և ժամանակին առաքման համար:",
      desc4:
        "դ. Հաղորդակցման մասին տեղեկություններ. մեր հաճախորդների աջակցության կենտրենի հետ ձեր նամակագրության գրառումները, ներառյալ էլ. նամակները:",
      title2: "2. Ինչպես ենք մենք օգտագործում ձեր տվյալները",
      subtitle2:
        "Մենք օգտագործում ենք մեր հավաքած տեղեկատվությունը հետևյալ նպատակների համար",
      desc5:
        "ա. Պատվերների մշակում։ Ձեր պատվերները մշակելու և կատարելու համար, ներառյալ առաքումը կազմակերպելը և անհրաժեշտության դեպքում Ձեզ հետ կապ հաստատելը հասցեի պարզաբանման համար:",
      desc6:
        "բ. Հաճախորդների սպասարկում։ Ձեր հարցումներին պատասխանելու, աջակցություն ցուցաբերելու և ցանկացած խնդրի լուծման համար, որոնց կարող եք հանդիպել:",
      desc7:
        "գ. Վճարումների մշակում. Ձեր պատվերների համար վճարումը հեշտացնելու համար մենք կարող ենք օգտագործել երրորդ կողմի անվտանգ վճարային պրոցեսորներ:",
      desc8:
        "դ. Մարքեթինգ և առաջխաղացումներ։ Ձեր համաձայնությամբ մենք կարող ենք ձեզ ուղարկել գովազդային առաջարկներ, տեղեկություններ նոր ապրանքների կամ ծառայությունների մասին:",
      title3: "3. Տեղեկատվության փոխանակում",
      subtitle3:
        "Մենք չենք վաճառում կամ վարձակալում Ձեր անձնական տվյալները երրորդ անձանց: Այնուամենայնիվ, մենք կարող ենք կիսվել ձեր տեղեկությունները հետևյալի հետ.",
      desc9:
        "ա. Ծառայություններ մատուցողներ. ինչպիսիք են առաքման գործընկերները, վճարումներ իրականացնողները և հաճախորդների սպասարկման առաքիչները, որոնք օգնում են մեզ իրականացնել տարբեր գործառույթներ:",
      desc10:
        "բ. Իրավական համապատասխանություն. Եթե պահանջվում է օրենքով կամ ի պատասխան վավեր իրավական գործընթացի, մենք կարող ենք բացահայտել ձեր տվյալները:",
      title4: "4. Անվտանգություն",
      desc11:
        "Մենք համապատասխան միջոցներ ենք ձեռնարկում ձեր տեղեկատվությունը արգելված մուտքից, բացահայտումից, փոփոխությունից և ոչնչացումից պաշտպանելու համար:",
      title5: "5. Փոփոխություններ սույն քաղաքականության մեջ",
      desc12:
        "Մենք կարող ենք ժամանակ առ ժամանակ թարմացնել այս Գաղտնիության քաղաքականությունը՝ արտացոլելու մեր գործելակերպի փոփոխությունները կամ այլ գործառնական, իրավական կամ կարգավորող պատճառներով: Թարմացված քաղաքականությունը կտեղադրվի մեր կայքում",
      title6: "6. Կապվեք մեզ հետ",
      desc13:
        "Եթե ունեք հարցեր կամ մտահոգություններ սույն Գաղտնիության քաղաքականության վերաբերյալ, խնդրում ենք կապվել մեզ հետ",
    },
  },
};
